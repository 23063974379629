import { Language } from "../types";

const content = {
  en: {
    headers: {},
    customer_rating: [
      {
        icon: "😔",
        label: "Sad",
        value: "detractor",
      },
      {
        icon: "😐",
        label: "Neutral",
        value: "neutral",
      },
      {
        icon: "😊",
        label: "Happy",
        value: "promoter",
      },
    ],
    restaurants: [
      "Crazy pizza",
      "Cova",
      "Twiga",
      "Russo's",
      "Wild and the moon",
      "Pierre Marcolini",
      "Pierre herme",
      "Mosavali",
      "Dar yema",
      "Salon de the",
      "Duck donuts",
    ],
  },
  ar: {},
  fr: {},
};

type Translations = Record<any, Partial<Record<Language, string>>>;

const _translation: Record<string, Partial<Record<Language, string>>> = {
  "IFLY WAIVER OF LIABILITY": {
    en: "IFLY WAIVER OF LIABILITY",
    ar: "التنازل عن المسؤولية",
  },
  "LASER OASIS WAIVER OF LIABILITY": {
    en: "LASER OASIS WAIVER OF LIABILITY",
    ar: "LASER OASIS WAIVER OF LIABILITY AR",
  },
  Checklist: {
    en: "Checklist",
    ar: "قائمة تدقيق",
  },
  Notes: {
    en: "Notes",
    ar: "ملحوظات",
  },
  "Terms and conditions": {
    en: "Terms and conditions",
    ar: "الأحكام والشروط",
  },
  "YOUR INFORMATION": {
    en: "YOUR INFORMATION",
    ar: "معلوماتك",
  },
  "DEPENDENTS / CHILDREN": {
    en: "DEPENDENTS / CHILDREN",
    ar: "المُعالون / الأطفال",
  },
  "Remove Dependents / Child": {
    en: "Remove Dependents / Child",
    ar: "إزالة المعالين / الطفل",
  },
  "Add Dependents / Child": {
    en: "Add Dependents / Child",
    ar: "إضافة المُعالين / الطفل",
  },
  Signature: {
    en: "Signature",
    ar: "إمضاء",
  },
  "Clear Signature": {
    en: "Clear Signature",
    ar: "مسح التوقيع",
  },
  Name: {
    en: "Name",
    ar: "اسم",
  },
  "Participant of parent/guardian": {
    en: "Participant of parent/guardian",
    ar: "مشارك من الوالدين / الوصي",
  },
  "Child's Name": {
    en: "Child's Name",
    ar: "اسم الطفل",
  },
  "QID/Passport#": {
    en: "QID/Passport#",
    ar: "البطاقة الشخصية / جواز السفر#",
  },
  "Your residence permit ID or passport number.": {
    en: "Your residence permit ID or passport number.",
    ar: "رقم تصريح الإقامة أو رقم جواز السفر.",
  },
  "Child's residence permit ID or passport number.": {
    en: "Child's residence permit ID or passport number.",
    ar: "بطاقة هوية إقامة الطفل أو رقم جواز السفر.",
  },
  Phone: {
    en: "Phone",
    ar: "هاتف",
  },
  "ex. 0097411222211, 97411222211 or 11223344": {
    en: "ex. 0097411222211, 97411222211 or 11223344",
    ar: "السابق. 0097411222211 أو 97411222211 أو 11223344",
  },
  "By submitting this form means you comply and understand above listed checklist, terms and conditions":
    {
      en: "By submitting this form means you comply and understand above listed checklist, terms and conditions",
      ar: "من خلال تقديم هذا النموذج يعني أنك تلتزم وتفهم قائمة المراجعة والشروط والأحكام المذكورة أعلاه",
    },
  Submit: {
    en: "Submit",
    ar: "إرسال",
  },
  "Submitting...": {
    en: "Submitting...",
    ar: "تقديم...",
  },
  "Thank you! Your Form ID is:": {
    en: "Thank you! Your Form ID is:",
    ar: "شكرًا لك! معرف النموذج الخاص بك هو:",
  },
  "iFly Waiver form has been submitted": {
    en: "iFly Waiver form has been submitted",
    ar: "تم تقديم نموذج التنازل عن iFly",
  },
  "Laser Oasis Waiver form has been submitted": {
    en: "Laser Oasis Waiver form has been submitted",
    ar: "Laser Oasis Waiver form has been submitted AR",
  },
  "Submit new waiver form": {
    en: "Submit new waiver form",
    ar: "تقديم نموذج تنازل جديد",
  },
  "Any comments or feedback?": {
    en: "Any comments or feedback?",
    ar: "أي تعليقات أو تعليقات؟",
    fr: "Any comments or feedback?",
  },
  "Survey F&B": {
    en: "Survey F&B",
    ar: "استبيان تقييم المطعم",
  },
  "Your Voice, Our Improvement": {
    en: "Your Voice, Our Improvement",
    ar: "صوتك، تطورنا",
  },
  "Share your dining experience to help us serve you better!": {
    en: "Share your dining experience to help us serve you better!",
    ar: "شارك تجربتك لمساعدتنا في خدمتك بشكل أفضل!",
  },
  "Meal Period": {
    en: "Meal Period",
    ar: "فترة الوجبة",
  },
  Breakfast: {
    en: "Breakfast",
    ar: "فطور الصباح",
  },
  Lunch: {
    en: "Lunch",
    ar: "الغداء",
  },
  Dinner: {
    en: "Dinner",
    ar: "العشاء",
  },
  "How often do you visit our restaurant?": {
    en: "How often do you visit our restaurant?",
    ar: "كم مرة تزور مطعمنا؟",
  },
  Daily: {
    en: "Daily",
    ar: "يوميًا",
  },
  Weekly: {
    en: "Weekly",
    ar: "أسبوعيًا",
  },
  Monthly: {
    en: "Monthly",
    ar: "شهريًا",
  },
  "First Time": {
    en: "First Time",
    ar: "أول مرة",
  },
  "How would you rate your dining experience?": {
    en: "How would you rate your dining experience?",
    ar: "كيف تقيم تجربتك ؟",
  },
  Greeting: {
    en: "Greeting",
    ar: "الترحيب",
  },
  Service: {
    en: "Service",
    ar: "الخدمة",
  },
  Food: {
    en: "Food",
    ar: "الأكل",
  },
  Beverage: {
    en: "Beverage",
    ar: "المشروبات",
  },
  "Value for Money": {
    en: "Value for Money",
    ar: "القيمة مقابل المال",
  },
  Cleanliness: {
    en: "Cleanliness",
    ar: "النظافة",
  },
  "How did you come to know about us?": {
    en: "How did you come to know about us?",
    ar: "من أين سمعت عنا؟",
  },
  "Please choose": {
    en: "Please choose",
    ar: "اختارمن فضلك يرجى الاختيار",
  },
  "Social Media": {
    en: "Social Media",
    ar: "وسائل التواصل الاجتماعي",
  },
  "Influencer Recommendation": {
    en: "Influencer Recommendation",
    ar: "توصية من أحد المؤثرين",
  },
  "Search Engines like Google, Bing, etc...": {
    en: "Search Engines like Google, Bing, etc...",
    ar: "محركات البحث مثل جوجل، بينغ، إلخ...",
  },
  "Word of Mouth": {
    en: "Word of Mouth",
    ar: "التوصية الشفوية",
  },
  Website: {
    en: "Website",
    ar: "الموقع إلكتروني",
  },
  Advertisement: {
    en: "Advertisement",
    ar: "إعلان",
  },
  SMS: {
    en: "SMS",
    ar: "رسالة نصية قصيرة",
  },
  Other: {
    en: "Other",
    ar: "شيء آخر",
  },
  "Dining satisfaction survey": {
    en: "Dining satisfaction survey",
    ar: "استبيان تقييم المطعم",
  },
  "Would you visit this restaurant again?": {
    en: "Would you visit this restaurant again?",
    ar: "هل ستزور هذا المطعم مرة أخرى؟",
  },
  Yes: {
    en: "Yes",
    ar: "نعم",
  },
  No: {
    en: "No",
    ar: "لا",
  },
  "Did the manager/supervisor visit your table?": {
    en: "Did the manager/supervisor visit your table?",
    ar: "هل زار المدير/المشرف طاولتك؟",
  },
  "Customer information": {
    en: "Customer information",
    ar: "معلومات العميل",
  },
  "Full Name": {
    en: "Full Name",
    ar: "الاسم الكامل",
  },
  "Enter Here": {
    en: "Enter Here",
    ar: "أدخل هنا",
  },
  "Country Code": {
    en: "Country Code",
    ar: "رمز الدولة",
  },
  "Phone Number": {
    en: "Phone Number",
    ar: "رقم الهاتف",
  },
  "Birth date": {
    en: "Birth date",
    ar: "تاريخ الميلاد",
  },
  "mm/dd/yyyy": {
    en: "mm/dd/yyyy",
    ar: "mm/dd/yyyy",
  },
  "Email Address": {
    en: "Email Address",
    ar: "البريد الإلكتروني",
  },
  "How Did We Do Today?": {
    en: "How Did We Do Today?",
    ar: "كيف كان أداؤنا اليوم؟",
  },
  "Please rate us.": {
    en: "Please rate us.",
    ar: "من فضلك قم بتقييمنا",
  },
  "Your Comments": {
    en: "Your Comments",
    ar: "تعليقاتك؟",
  },
  "Your comments?": {
    en: "Your comments?",
    ar: "تعليقاتك؟",
  },
  "Select rating": { en: "Select rating", ar: "حدد التقييم" },
  Poor: { en: "Poor", ar: "فقير" },
  Good: { en: "Good", ar: "جيد" },
  Average: { en: "Average", ar: "متوسط" },
  "Very Good": { en: "Very Good", ar: "جيد جدًا" },
  Excellent: { en: "Excellent", ar: "ممتاز" },
  "Would you allow us to contact you for any future promotions, events and informations about our restaurants?":
    {
      en: "Would you allow us to contact you for any future promotions, events and informations about our restaurants?",
      ar: "هل توافق على أن نتواصل معك بخصوص العروض والفعاليات والمعلومات المستقبلية عن مطاعمنا؟",
    },

  "Follow us on social media or visit our website for updates and offers.": {
    en: "Follow us on social media or visit our website for updates and offers.",
    ar: "تابعنا عبر وسائل التواصل الاجتماعي أو قم بزيارة موقعنا لمعرفة أحدث التحديثات والعروض.",
  },
  "We Appreciate Your Input!": {
    en: "We Appreciate Your Input!",
    ar: "نقدّر مساهمتك",
  },
  "Thanks for taking the time to share your thoughts with us.": {
    en: "Thanks for taking the time to share your thoughts with us.",
    ar: "شكرًا على تخصيص وقتكم لمشاركة أفكاركم معنا.",
  },
  "Stay connected with us": {
    en: "Stay connected with us",
    ar: "ابقَ على تواصل معنا",
  },
};

export const t = (text: string, language: Language) => {
  const textLowered = text.toLowerCase();
  const loweredCasedTranslations: Translations = {};

  for (let key in _translation) {
    if (key.toLowerCase() === textLowered) {
      loweredCasedTranslations[textLowered] = _translation[key];
      break;
    }
  }

  if (!loweredCasedTranslations[textLowered]) {
    return text;
  }

  const translatedText = loweredCasedTranslations[textLowered][language] || "";
  return translatedText;
};

export default content;
